import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getCurrentCompany } from "src/Store/localStore/localStorage";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_GRAPHQL_URL}`,
  credentials: "include",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const _currentMeCompany = getCurrentCompany();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "x-company-id": _currentMeCompany?.id ? _currentMeCompany?.id : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    possibleTypes: {
      Resource: ["Company", "Facility", "System", "Equipment", "SubEquipment"],
      Identity: ["CompanyIdentity", "UserIdentity", "TeamIdentity", "TeamMemberIdentity"],
    },
  }),
});

export default client;
