import { lazy } from "react";

//private
const RouterPrivate = lazy(() => import("./routerPrivate"));
const RouterAdmin = lazy(() => import("./routerAdmin"));

// membership
const RouterMembership = lazy(() => import("./routerMembership"));
const Login = lazy(() => import("../Views/Auth/Login"));
const LoginPassword = lazy(() => import("../Views/Auth/LoginPassword"));
const PasswordCreate = lazy(() => import("../Views/Auth/PasswordCreate"));
const Registration = lazy(() => import("../Views/Auth/Registration"));
const ForgotMyPassword = lazy(() => import("../Views/Auth/ForgotMyPassword"));
const Confirmation = lazy(() => import("../Views/Auth/Confirmation"));
const Lobby = lazy(() => import("../Views/Pages/Lobby"));
const WellcomeUser = lazy(() => import("../Views/Pages/WellComeUser"));
const CreateAccount = lazy(() => import("../Views/Auth/CreateAccount"));
const CreateCompany = lazy(() => import("../Views/Pages/CreateCompany"));
const ConfirmRegistration = lazy(() => import("../Views/Auth/ConfirmRegistration"));

export {
  RouterPrivate,
  RouterAdmin,
  RouterMembership,
  // membership
  Registration,
  ConfirmRegistration,
  Login,
  LoginPassword,
  PasswordCreate,
  ForgotMyPassword,
  Confirmation,
  Lobby,
  WellcomeUser,
  CreateAccount,
  CreateCompany,
};
