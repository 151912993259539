export const trLOCALES = {
  schedule: {
    day: "Gün",
    week: "Hafta",
    workWeek: "İş Günü",
    month: "Ay",
    agenda: "Ajanda",
    weekAgenda: "Week Agenda",
    workWeekAgenda: "Work Week Agenda",
    monthAgenda: "Month Agenda",
    today: "Today",
    noEvents: "No events",
    emptyContainer: "There are no events scheduled on this day.",
    allDay: "All day",
    start: "Start",
    end: "End",
    more: "daha",
    close: "Close",
    cancel: "Cancel",
    noTitle: "(No Title)",
    delete: "Delete",
    deleteEvent: "Delete Event",
    deleteMultipleEvent: "Delete Multiple Events",
    selectedItems: "Items selected",
    deleteSeries: "Delete Series",
    edit: "Düzenle",
    editSeries: "Edit Series",
    editEvent: "Edit Event",
    createEvent: "Create",
    subject: "Subject",
    addTitle: "Add title",
    moreDetails: "More Details",
    save: "Save",
    editContent: "Do you want to edit only this event or entire series?",
    deleteRecurrenceContent: "Do you want to delete only this event or entire series?",
    deleteContent: "Are you sure you want to delete this event?",
    deleteMultipleContent: "Are you sure you want to delete the selected events?",
    newEvent: "New Event",
    title: "Title",
    location: "Location",
    description: "Description",
    timezone: "Timezone",
    startTimezone: "Start Timezone",
    endTimezone: "End Timezone",
    repeat: "Repeat",
    saveButton: "Save",
    cancelButton: "Cancel",
    deleteButton: "Delete",
    recurrence: "Recurrence",
    wrongPattern: "The recurrence pattern is not valid.",
    seriesChangeAlert:
      "The changes made to specific instances of this series will be cancelled and those events will match the series again.",
    createError:
      "The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.",
    recurrenceDateValidation:
      "Some months have fewer than the selected date. For these months, the occurrence will fall on the last date of the month.",
    sameDayAlert: "Two occurrences of the same event cannot occur on the same day.",
    editRecurrence: "Edit Recurrence",
    repeats: "Repeats",
    alert: "Alert",
    startEndError: "The selected end date occurs before the start date.",
    invalidDateError: "The entered date value is invalid.",
    ok: "Ok",
    occurrence: "Occurrence",
    series: "Series",
    previous: "Previous",
    next: "Next",
    timelineDay: "Timeline Day",
    timelineWeek: "Timeline Week",
    timelineWorkWeek: "Timeline Work Week",
    timelineMonth: "Timeline Month",
    expandAllDaySection: "Expand",
    collapseAllDaySection: "Collapse",
  },
  recurrenceeditor: {
    none: "None",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    month: "Month",
    yearly: "Yearly",
    never: "Never",
    until: "Until",
    count: "Count",
    first: "First",
    second: "Second",
    third: "Third",
    fourth: "Fourth",
    last: "Last",
    repeat: "Repeat",
    repeatEvery: "Repeat Every",
    on: "Repeat On",
    end: "End",
    onDay: "Day",
    days: "Day(s)",
    weeks: "Week(s)",
    months: "Month(s)",
    years: "Year(s)",
    every: "every",
    summaryTimes: "time(s)",
    summaryOn: "on",
    summaryUntil: "until",
    summaryRepeat: "Repeats",
    summaryDay: "day(s)",
    summaryWeek: "week(s)",
    summaryMonth: "month(s)",
    summaryYear: "year(s)",
  },
};
