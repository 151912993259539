import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Loading from "../Components/Components/loading/loading";
import {
  RouterPrivate,
  RouterMembership,
  Confirmation,
  Lobby,
  RouterAdmin,
  WellcomeUser,
  CreateAccount,
  CreateCompany,
} from "./routerComponents";
import { Redirect } from "react-router";

const Router = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path="/login" component={RouterMembership} />
        <Route path="/verifyAccount" component={Confirmation} />
        <Route path="/createAccount" component={CreateAccount} />
        <Route path="/createCompany" component={CreateCompany} />
        <Route path="/wellcome" component={WellcomeUser} />
        <Route path="/lobby" component={Lobby} />
        <Route path={["/c/:company_id", "/wo/:workOrder_id"]} component={RouterPrivate} />
        <Route path={"/admin"} component={RouterAdmin} />
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default Router;
